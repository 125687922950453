<template>
  <div class="badge rounded bg-light f-12">
    <span v-if="isVip" class="icon-star text-warning"></span>
    <span v-if="isVip" class="icon-star text-warning"></span>
    <span class="icon-star text-warning"></span>
    <span
      :class="isVip ? 'text-warning' : 'text-dark'"
      class="text-uppercase ml-1"
      ><b>{{ standing }}</b></span
    >
  </div>
</template>

<script>
export default {
  name: "StandingTripItem",
  props: {
    standing: {
      type: String,
      required: true,
    },
  },
  computed: {
    isVip() {
      return this.standing === "vip";
    },
  },
};
</script>

<style scoped></style>
