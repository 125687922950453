<template>
  <form @submit.prevent="handlePassengerForm">
    <h4 class="divider mt-4 px-4" />
    <div class="row">
      <div class="col-lg-6 col-12 mt-4">
        <BaseInput
          v-model="passengerInput.name"
          placeholder="Entrez le nom et le prénom"
          label="Nom &amp; Prénom"
          :errorMessage="nameInputValidation"
          @input="$v.passengerInput.name.$touch()"
        />
      </div>
      <div class="col-lg-6 col-12 mt-4">
        <be-multiselect
          v-model="passengerInput.id_type"
          :options="options"
          inputLabel="Type d'identification"
          placeholder="Choisir le type d'identification"
          :errorMessage="selectValidation"
          track-by="value"
          label="label"
          :close-on-select="true"
          onlyKey
          @input="$v.passengerInput.id_type.$touch()"
        />
      </div>
      <div class="col-lg-6 col-12 mt-4">
        <BaseInput
          v-if="showValueInput"
          v-model="passengerInput.id_value"
          label="Code d'identification"
          placeholder="Entrez le code"
          :errorMessage="passengerInputValidation"
          @input="$v.passengerInput.id_value.$touch()"
        />
      </div>
      <div
        v-if="showValueInput"
        class="col-lg-6 col-12 mt-4 d-md-flex align-items-end justify-content-center"
        :class="{
          'pb-4': passengerInputValidation,
        }"
      >
        <!-- v-if="canShowCancelBtn" -->
        <div class="d-flex flex-column-reverse flex-md-row align-items-center">
          <span
            @click.prevent="$emit('close')"
            class="f-14 f-w-600 pointer mr-3 mt-3 mt-md-0"
          >
            Retour
          </span>
          <BaseButton
            buttonClass="i-left be-btn primary blue sm mb-0"
            :disabled="$v.$invalid || loading"
            :loading="loading"
            :show-loading="true"
          >
            <i class="icon-plus" />
            Enregistrer le passager
          </BaseButton>
        </div>
      </div>
    </div>
    <!-- <div class="form-group row align-items-center" v-if="showValueInput">
      <label class="col-lg-4 col-form-label text-lg-right">
        Code d'identification
        <span class="text-danger">*</span>
      </label>
      <div class="col-lg-6">
        <input
          type="text"
          class="form-control"
          v-model="passengerInput.id_value"
          placeholder="Entrez le code"
          :class="{
            'is-invalid':
              $v.passengerInput.id_value.$error ||
              errors.id_value !== undefined,
          }"
        />
        <template v-if="$v.passengerInput.id_value.$error || errors.id_value">
          <label class="small form-text text-danger text-right">
            <em v-if="!$v.passengerInput.id_value.required"
              >Veuillez saisir le code <b>{{ passengerInput.id_type }}</b></em
            >
            <em v-if="errors.id_value">{{ errors.id_value[0] }}</em>
          </label>
        </template>
      </div>
    </div> -->
    <be-form-error v-if="error" :message="error" />
    <!-- <div class="form-group row">
      <div class="col-lg-10 text-lg-right">
        <div
          class="form-group row justify-content-center justify-content-sm-end"
        >
          <button
            role="button"
            @click.prevent="$emit('close')"
            type="button"
            v-if="canShowCancelBtn"
            class="btn btn-outline-danger"
          >
            <i class="fa fa-times mr-0"></i> Fermer
          </button>
          <BaseButton
            buttonClass="btn-secondary"
            :disabled="$v.$invalid || loading"
            :loading="loading"
            :show-loading="true"
          >
            <i class="fa fa-plus" v-if="!loading"></i>
            Enregistrer <span class="d-none d-sm-inline">le passager</span>
          </BaseButton>
        </div>
      </div>
    </div> -->
    <hr />
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import BeMultiselect from '@/components/common/BeMultiselect';
import BeFormError from '@/components/common/BeFormError.vue';

export default {
  name: 'PassengerForm',
  components: {
    BeMultiselect,
    BeFormError,
  },

  props: {
    canShowCancelBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      errors: [],
      error: null,
      passengerInput: {
        name: null,
        id_type: null,
        id_value: null,
      },
      options: [
        {
          value: 'cni',
          label: "Carte national d'identité",
        },
        {
          value: 'passport',
          label: 'Passeport',
        },
        {
          value: 'permis',
          label: 'Permis de conduire',
        },
        {
          value: 'scolaire',
          label: 'Carte scolaire',
        },
        {
          value: 'mineur',
          label: 'Enfant',
        },
      ],
    };
  },

  computed: {
    showValueInput() {
      return this.passengerInput.id_type
        ? this.passengerInput.id_type !== 'mineur'
        : false;
    },
    nameInputValidation() {
      if (this.$v.passengerInput.name.$error || this.errors.name) {
        if (!this.$v.passengerInput.name.required) {
          return 'Veuillez saisir le nom et le prénom';
        }
        if (this.errors.name) {
          return this.errors.name[0];
        }
      }
      return '';
    },
    selectValidation() {
      if (this.$v.passengerInput.id_type.$error || this.errors.id_type) {
        if (!this.$v.passengerInput.id_type.required) {
          return "Veuillez Sélectionner le type d'indentification";
        }
        if (this.errors.id_type) {
          return this.errors.id_type[0];
        }
      }
      return '';
    },
    passengerInputValidation() {
      if (this.$v.passengerInput.id_value.$error || this.errors.id_value) {
        if (!this.$v.passengerInput.id_value.required) {
          return `Veuillez saisir le code ${this.passengerInput.id_type}`;
        }
        if (this.errors.id_value) {
          return this.errors.id_value[0];
        }
      }
      return '';
    },
  },

  methods: {
    ...mapActions('shopping', ['addPassenger']),

    handlePassengerForm() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        this.$store
          .dispatch('shopping/addPassenger', this.passengerInput)
          .then(passenger => {
            this.$emit('saving', passenger);
            this.loading = false;
          })
          .catch(err => {
            if (err.response.data.message)
              this.error = err.response.data.message;

            if (err.response.data.errors)
              this.errors = err.response.data.errors;

            this.loading = false;
          });
      }
    },
  },

  validations() {
    return {
      passengerInput: {
        name: { required },
        id_type: { required },
        id_value: {
          required: requiredIf(() => this.passengerInput.id_type !== 'mineur'),
        },
      },
    };
  },
};
</script>

<style scoped></style>
