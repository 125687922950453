<template>
  <div class="row px-4">
    <div class="col-lg-6 col-10 w-100px">
      <be-multiselect
        inputLabel="Passager"
        :options="passengersList"
        placeholder="Choisir un passager"
        v-model="passenger_id"
        onlyKey
        track-by="id"
        label="label"
      />
    </div>
    <div class="col-lg-1 col-2 d-flex p-0">
      <div class="edit-btn">
        <button
          :disabled="!enableAddBtn"
          @click.prevent="addSelectPassenger"
          title="Sélectionner le passager"
          class="be-btn primary blue lg circle-btn"
        >
          <i class="icon-plus" />
        </button>
      </div>
    </div>
    <div class="col-md-12 px-4" v-if="showPassengerForm">
      <PassengerForm
        @saving="setNewPassenger"
        :showCancelBtn="canShowCancelBtn"
        @close="passenger_id = null"
      />
    </div>
  </div>
  <!-- <div class="input-group"> -->
  <!-- <select
          class="form-control"
          id="passenger"
          v-model="passenger_id"
          :disabled="showPassengerForm"
        >
          <option :value="null" disabled>--Choisir un passager--</option>
          <option
            :value="passenger.id"
            v-for="passenger in passengers"
            :key="passenger.id"
            :disabled="disabledPassenger(passenger.name)"
          >
            {{
              passenger.name +
                ' - ' +
                passenger.id_type +
                (passenger.id_value ? `/${passenger.id_value}` : '')
            }}
          </option>
          <option :value="'-1'">+ Ajouter un passager</option>
        </select> -->

  <!-- <div class="input-group-append">
          <button
            role="button"
            type="button"
            :disabled="!enableAddBtn"
            @click.prevent="addSelectPassenger"
            class="btn btn-secondary"
            title="Ajouter le passager"
          >
            <i class="mr-0 fa icon-plus"></i>
          </button>
        </div> -->
  <!-- </div> -->
</template>

<script>
import { mapState } from "vuex";
import PassengerForm from "./PassengerForm";
import BeMultiselect from "@/components/common/BeMultiselect.vue";

export default {
  name: "PassengerSelector",
  components: { PassengerForm, BeMultiselect },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      passenger_id: null,
      information: null,
    };
  },

  computed: {
    ...mapState("shopping", ["passengers"]),
    enableAddBtn() {
      if (this.passenger_id) {
        if (this.passenger_id === -1) return false;
        else {
          const passenger = this.passengers.find(
            p => p.id === this.passenger_id
          );
          if (passenger !== undefined)
            return !this.disabledPassenger(passenger.name);
          else return false;
        }
      } else return false;
    },
    showPassengerForm() {
      return this.passenger_id === -1;
    },
    canShowCancelBtn() {
      return this.passengers.length > 0;
    },
    passengersList() {
      const pass = this.passengers.map(p => ({
        ...p,
        label:
          p.name + " - " + p.id_type + (p.id_value ? `/${p.id_value}` : ""),
        disabledOption: this.disabledPassenger(p.name),
      }));
      return [...pass, { label: "+ Ajouter un passager", id: -1 }];
    },
  },

  created() {
    this.$store.dispatch("shopping/getPassengers").then(passengers => {
      if (passengers.length === 0) this.passenger_id = -1;
      this.loading = false;
    });
  },

  methods: {
    disabledPassenger(name) {
      return this.list.find(l => l.fullname === name) !== undefined;
    },
    addSelectPassenger() {
      const passenger = this.passengers.find(p => p.id === this.passenger_id);
      if (passenger !== undefined)
        this.$emit("select", {
          fullname: passenger.name,
          identity_type: passenger.id_type,
          identity_value: passenger.id_value,
        });
    },
    setNewPassenger(passenger) {
      this.passenger_id = passenger.id;
    },
  },
};
</script>

<style scoped></style>
