<template>
  <div class="payer-un-service be-row justify-content-center">
    <div class="left-side d-none d-xl-flex">
      <div class="left-icon-container">
        <div class="icon-div-payer">
          <i class="icon-card-fill"></i>
        </div>
        <div class="left-icon-text">
          {{ $t("shopping.buy") }} <br />
          {{ $t("shopping.a_ticket") }}
        </div>
      </div>
    </div>
    <div class="right-side col-xl-8 px-0">
      <div class="be-card scroll-wrapper">
        <h4 class="with-divider p-4 f-16 f-w-600 blue">
          <span class="blue">
            {{ $t("shopping.travel_infos") }}
          </span>
        </h4>
        <form class="scroll-content" @submit.prevent="handleShoppingTravel">
          <div class="max-height-1px">
            <div class="">
              <p class="f-18 f-w-600 text-center">
                {{ $t("shopping.travel_of") }}
                {{ travel.date | moment("dddd, Do MMMM YYYY") }}
                <br />
                {{
                  $t("shopping.travel_from_to", {
                    from: `${travel.departure_city} (${travel.departure_location})`,
                    to: `${travel.arrival_city} (${travel.arrival_location})`,
                  })
                }}
              </p>
              <p class="f-16 text-center my-4">
                {{ $t("shopping.ticket_expiration") }}
                {{ travel.end_of_online_sales | moment("from", "now") }}
              </p>
              <div
                v-if="isAlertShown"
                aria-hidden="true"
                class="alert alert-box-bwallet m-4"
              >
                <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="alert"
                  type="button"
                  @click="isAlertShown = false"
                >
                  <i class="icon-close" />
                </button>
                <p class="f-w-600 f-16">{{ $t("common.please_note") }}</p>
                <div class="mt-4 tax-info">
                  <div class="pt-2 f-12 pb-2">
                    {{ $t("common.you_ll_receive_code_by_sms") }}
                    <span class="be-text-primary">{{ phone }}.</span>
                  </div>
                  <div class="pt-2 f-12 pb-2">
                    {{ $t("shopping.travel_process_notes") }}
                  </div>
                </div>
              </div>
              <PassengerSelector
                :list="passengers"
                @select="passengers.push($event)"
              />
              <PassengerList
                v-if="passengers.length"
                :passengers="passengers"
                class="mt-4"
                @remove="removePassengerByIndex"
              />
              <div class="shoppingConfirmCard m-4">
                <div class="shopping-confirm-body be-row">
                  <div class="col-4">
                    <div class="sh-card-title">
                      {{ $t("shopping.departure_time") }}
                    </div>
                    <div class="sh-card-description">
                      {{ departureDatetime | moment("H") }}:
                      {{ departureDatetime | moment("mm") }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sh-card-title">
                      {{ $t("shopping.arriving_time") }}
                    </div>
                    <div class="sh-card-description">
                      {{ arrivalDatetime | moment("H") }}:
                      {{ arrivalDatetime | moment("mm") }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sh-card-title">
                      {{ $t("common.travel") }}
                    </div>
                    <div class="sh-card-description">
                      <StandingTripItem :standing="travel.standing_trip" />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sh-card-title">
                      <span class="d-none d-sm-inline">
                        {{ $t("shopping.number_of_tickets_still_available") }}
                      </span>
                      <span class="d-inline d-sm-none">
                        {{ $t("shopping.available_tickets") }}
                      </span>
                    </div>
                    <div class="sh-card-description">
                      {{ travel.seat_available_count }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sh-card-title">
                      {{ $t("common.unit_price") }}
                    </div>
                    <div class="sh-card-description">
                      {{ `${travel.per_seat_fare} ${currency}` }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sh-card-title">
                      {{ $t("shopping.passengers_number") }}
                    </div>
                    <div class="sh-card-description">
                      {{ passengers.length || $t("shopping.no_passengers") }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sh-card-title">
                      {{ $t("common.subtotal") }}
                    </div>
                    <div class="sh-card-description">
                      <template v-if="passengers.length"
                        >{{ passengers.length }} *
                        <span>{{ travel.per_seat_fare }}</span> =
                      </template>
                      {{ `${subTotal} ${currency}` }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sh-card-title">
                      {{ $t("common.fees") }}
                      <template v-if="service.frais > 0">
                        (<span class="text-c-red">{{ service.frais }}%</span>)
                      </template>
                    </div>
                    <div class="sh-card-description">
                      {{ `${feeAmount} ${currency}` }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sh-card-title">
                      {{ $t("common.total_price") }}
                    </div>
                    <div class="sh-card-description">
                      {{ `${totalAmount} ${currency}` }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <be-form-error v-if="error" :message="error" />

            <div v-if="error" class="form-group row">
              <div class="col-lg-8 offset-lg-4">
                <ul class="list-unstyled">
                  <li v-if="error" class="text-danger">
                    <i class="fa fa-times"></i> {{ error }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </form>
        <div class="p-4">
          <div class="divider" />
        </div>
        <div
          class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-end p-3"
        >
          <span
            class="f-14 f-w-600 mr-md-4 mr-0 pointer"
            @click.prevent="$router.back()"
          >
            {{ $t("common.back") }}
          </span>
          <BaseButton
            :disabled="!activateConfirmBtn"
            :loading="loading"
            :show-loading="true"
            buttonClass="be-btn lg btn-secondary i-left mb-0"
            class="mb-3 mb-md-0"
            type="button"
            @click.prevent="handleShoppingTravel"
          >
            {{ $t("shopping.confirm_payment") }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { travelTripMixin } from "../../mixins/travelTripMixin";
import PassengerSelector from "../../components/shopping/PassengerSelector";
import PassengerList from "../../components/shopping/PassengerList";
import { mapGetters } from "vuex";
import StandingTripItem from "../../components/shopping/StandingTripItem";
import BeFormError from "@/components/common/BeFormError";

export default {
  name: "ShoppingProcessTravel",
  components: {
    StandingTripItem,
    PassengerList,
    PassengerSelector,
    BeFormError,
  },
  mixins: [travelTripMixin],
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      passengers: [],
      isAlertShown: true,
    };
  },

  computed: {
    ...mapGetters("auth", ["userStats", "phone", "currency"]),
    subTotal() {
      return this.passengers.length * parseFloat(this.travel.per_seat_fare);
    },
    feeAmount() {
      if (this.service.frais > 0)
        return ((this.service.frais * this.subTotal) / 100).toFixed(0);
      else return 0;
    },
    totalAmount() {
      return parseFloat(this.subTotal) + parseFloat(this.feeAmount);
    },
    activateConfirmBtn() {
      if (this.passengers.length > 0)
        return this.userStats.balance >= this.totalAmount;
      else return false;
    },
  },

  methods: {
    removePassengerByIndex(index) {
      this.passengers.splice(index, 1);
    },
    handleShoppingTravel() {
      if (this.activateConfirmBtn) {
        this.loading = true;
        this.$store
          .dispatch("shopping/processTravelPayment", {
            index: this.$route.params.index,
            travel: this.travel,
            passengers: this.passengers,
          })
          .then(() => {
            this.$router.push({ name: "shopping.list" });
          })
          .catch(error => {
            if (error.message) this.error = error.message;
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
