import { travelTimeIsNight } from "../helpers/utils";
import { mapGetters } from "vuex";

export const travelTripMixin = {
  props: {
    travel: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["currency"]),
    isNight() {
      return travelTimeIsNight(this.travel);
    },
    standingTripTextColor() {
      return this.travel.standing_trip.toLowerCase() === "vip"
        ? "text-warning"
        : "";
    },
    isAvailableService() {
      return (
        this.travel.services_trip.find(s => s.availalibilty === 1) !== undefined
      );
    },
    departureDatetime() {
      return this.travel.date + " " + this.travel.departure_time;
    },
    arrivalDatetime() {
      return this.travel.date + " " + this.travel.arrival_time;
    },
  },
};
