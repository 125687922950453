<template>
  <div class="passagers-container">
    <span class="f-w-600 f-16 my-4">Informations Sur Les Passagers</span>
    <div
      v-for="(passenger, index) in passengers"
      :key="index"
      class="f-w-600 w-100 pb-4 px-4 f-14 d-flex identity-row align-items-center justifiy-content-between"
    >
      <div
        class="d-flex align-items-center flex-column flex-md-row passager-info"
      >
        <span>
          {{ passenger.fullname }}
        </span>
        <div class="identity">
          {{ passenger.identity_type }}
          <span>{{
            passenger.identity_value ? ` / ${passenger.identity_value}` : ''
          }}</span>
        </div>
      </div>
      <div class="dashed-line"></div>
      <button
        class="btn p-1 btn-link text-danger m-0"
        data-toggle="tooltip"
        title="Retirer le passager"
        data-original-title="Retirer le passager"
        @click.prevent="$emit('remove', index)"
      >
        <i class="icon-person-delete be-text-failure f-18" />
      </button>
    </div>
    <!-- <span
      v-if="passengers.length"
      id="popover-target"
      class="be-text-primary pointer"
    >
      {{ passengers.length }}
    </span>
    <span v-else class="text-danger m-r-20"><em>Pas de passagers</em></span>
    <b-popover
      v-if="passengers.length"
      target="popover-target"
      triggers="hover"
      placement="top"
    >
      <ul class="list-unstyled text-left">
        <li
          class="text-truncate text-center"
          v-for="(passenger, index) in passengers"
          :key="index"
        >
          <div
            :class="{
              'divider mt-2': index,
            }"
          />
          <button
            class="btn p-1 btn-link text-danger m-0"
            data-toggle="tooltip"
            title="Retirer le passager"
            data-original-title="Retirer le passager"
            @click.prevent="$emit('remove', index)"
          >
            <i class="fa fa-minus mr-0" />
          </button>
          {{ passenger.fullname }}
          <span class="d-block text-center">
            <span class="f-w-500">{{ passenger.identity_type }}</span>
            {{
              passenger.identity_value ? ` / ${passenger.identity_value}` : ''
            }}
          </span>
        </li>
      </ul>
    </b-popover> -->
  </div>
</template>

<script>
export default {
  name: 'PassengerList',
  props: {
    passengers: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.passagers-container {
  border: 1px dashed #c3cbe3;
  margin: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.identity {
  font: normal normal normal 12px/17px Open Sans;
  color: #0b1527;
  opacity: 0.5;
  margin-left: 12px;
  display: flex;
}
.passager-info {
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}
.dashed-line {
  height: 1px;
  border: 1px dashed #c3cbe3;
  flex: 1;
  margin: 0px 12px;
}
</style>
